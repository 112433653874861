import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

// Connects to data-controller="datepicker"
export default class extends Controller {
  open(e) {
    e.preventDefault();
    flatpickr(e.target, { disableMobile: 'true' });
  }
}
